import React from 'react';
import Helmet from 'react-helmet';
import { BrandedCallout } from '@latitude/callout';
import { Link } from '@latitude/link';
import Icon from '@latitude/svg-inline/images/external-link.svg';
import { StickyNavigation } from '@latitude/sticky-navigation';
import Layout from '@/components/layout';
import Lframe from '@/components/Lframe/Lframe';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import { BREAKPOINT } from '@latitude/core/utils/constants';

import EssentialNumbersSection from './sections/_essential-numbers';
import ProductEnquiriesSection from './sections/_productEnquiriesSection';
import OtherEnquiriesSection from './sections/_otherEnquiriesSection';
import OnlineHelpSection from './sections/_onlineHelpSection';
import PageData from './contact-us.json';

const ContactUsPage = props => (
  <Layout location={props.location}>
    <main
      className="navigation-spacer"
      css={`
        background-color: #f8f8f8;
      `}
    >
      <Helmet>
        <link
          rel="canonical"
          href="https://www.latitudefinancial.com.au/contact-us/"
        />
        <title>{PageData.metaTitle}</title>
        <meta name="description" content={PageData.metaDesc} />
      </Helmet>

      <Lframe contactUs />

      <HeroBranded
        css={`
          @media (min-width: ${BREAKPOINT.XL}) {
            .HeroContent {
              padding-bottom: 80px;
            }
          }
        `}
        title={PageData.content.hero.title}
        text={PageData.content.hero.intro}
      />

      <div
        className="d-none d-lg-block"
        css="position: relative; z-index: 11;
        .sticky-navigation--fixed {
          position: fixed;
          }"
      >
        <StickyNavigation
          items={PageData.nav}
          isSticky
          offsetElem="[data-sticky-navigation-offset]"
          css={`
            background-color: #fff;
          `}
        />
      </div>

      <OnlineHelpSection />

      <EssentialNumbersSection />

      <ProductEnquiriesSection />

      <OtherEnquiriesSection />

      <div id="hearing-impaired">
        <BrandedCallout
          cta={
            <Link
              href="https://www.nzrelay.co.nz/"
              button="secondary"
              target="_blank"
              trackId="contact-us--national-relay-service--fom"
              disableDefaultEventTracking
              css={`
                margin: 20px 0 0;
              `}
            >
              Visit New Zealand Relay Service &nbsp;
              <Icon />
            </Link>
          }
          hasConfettiBackground
          moreVerticalPadding
          heading="Are you hearing impaired?"
          line1={
            <span>
              Gem uses the New Zealand Relay Service to ensure we are
              contactable for customers who are deaf or have a hearing or speech
              impairment.
            </span>
          }
        />
      </div>
    </main>
  </Layout>
);

export default ContactUsPage;
